<template>
  <div class="submitPicStyle">
    <div class="page-container">
      <h1 class="title">AI生图风格提交页面</h1>
      <form @submit.prevent="handleSubmit" class="form-container">
        <div class="style-select-wrapper">
          <label for="styleSelect">选择AI生图风格:</label>
          <select id="styleSelect" v-model="selectedStyle">
            <option value="">请选择风格</option>
            <option value="natural">自然风光</option>
            <option value="abstract">抽象艺术</option>
            <option value="futuristic">未来科技</option>
            <option value="retro">复古风格</option>
          </select>
        </div>
        <div class="input-group">
          <label for="keywords">风格关键字信息:</label>
          <input
            type="text"
            id="keywords"
            v-model="keywords"
            placeholder="请输入关键字信息"
            class="keywords-input"
          />
        </div>
        <div class="image-upload-wrapper">
          <label for="styleImage">风格示例图:</label>
          <input
            type="file"
            id="styleImage"
            @change="onImageChange"
            class="image-input"
          />
          <div
            v-if="imageSrc"
            class="image-preview"
            :style="{ backgroundImage: `url('${imageSrc}')` }"
          ></div>
        </div>
        <button type="submit" class="submit-button">提交</button>
      </form>
      <div v-if="loading" class="loading-overlay">
        <div class="spinner">
          <i class="fas fa-spinner fa-spin"></i>
          <!-- 假设你已经引入了Font Awesome -->
        </div>
      </div>
    </div>
    <div class="gallery-container">
      <h2 class="gallery-title">看看用户已提交的AI生图风格吧</h2>
      <div class="style-gallery">
        <div
          v-for="style in styles"
          :key="style.id"
          class="style-card"
          @click="openModal(style)"
        >
          <div class="style-image">
            <img :src="style.imageUrl" :alt="style.name" class="style-img" />
            <div class="style-name">{{ style.name }}</div>
          </div>
          <div class="keywords-info">{{ style.keywords }}</div>
        </div>
      </div>
      <!-- 使用模态框来展示图片，这里省略了模态框的实现代码 -->
      <!-- <modal :visible="modalVisible" @close="modalVisible = false">
        <img :src="selectedStyle.imageUrl" alt="Selected Style">
      </modal> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedStyle: "",
      keywords: "",
      imageSrc: null,
      loading: false,
      // 模态框是否可见
      // modalVisible: false,
      // 选中的样式，用于在模态框中展示
      // selectedStyle: null,
      styles: [
        {
          id: 1,
          name: "写实",
          keywords:
            "emoticons,various expressions of a litter cartoon bear,wearing a bib,Expressions such as happy,laughing,shy,angry,surprised,puzzled,acting cute,curious,and so on --ar 1:1 --s 250 --niji 5 --style cute",
          imageUrl:
            "http://www.soutudashi.com/get_image/" +
            encodeURIComponent("bz/00319030-5990-42ad-af89-d3e57e020134.webp"),
        },
        {
          id: 1,
          name: "写实",
          keywords:
            "emoticons,various expressions of a litter cartoon bear,wearing a bib,Expressions such as happy,laughing,shy,angry,surprised,puzzled,acting cute,curious,and so on --ar 1:1 --s 250 --niji 5 --style cute",
          imageUrl:
            "http://www.soutudashi.com/get_image/" +
            encodeURIComponent("bz/00319030-5990-42ad-af89-d3e57e020134.webp"),
        },
        {
          id: 1,
          name: "写实",
          keywords:
            "emoticons,various expressions of a litter cartoon bear,wearing a bib,Expressions such as happy,laughing,shy,angry,surprised,puzzled,acting cute,curious,and so on --ar 1:1 --s 250 --niji 5 --style cute",
          imageUrl:
            "http://www.soutudashi.com/get_image/" +
            encodeURIComponent("bz/00319030-5990-42ad-af89-d3e57e020134.webp"),
        },
        {
          id: 1,
          name: "写实",
          keywords:
            "emoticons,various expressions of a litter cartoon bear,wearing a bib,Expressions such as happy,laughing,shy,angry,surprised,puzzled,acting cute,curious,and so on --ar 1:1 --s 250 --niji 5 --style cute",
          imageUrl:
            "http://www.soutudashi.com/get_image/" +
            encodeURIComponent("bz/00319030-5990-42ad-af89-d3e57e020134.webp"),
        },
        {
          id: 1,
          name: "写实",
          keywords:
            "emoticons,various expressions of a litter cartoon bear,wearing a bib,Expressions such as happy,laughing,shy,angry,surprised,puzzled,acting cute,curious,and so on --ar 1:1 --s 250 --niji 5 --style cute",
          imageUrl:
            "http://www.soutudashi.com/get_image/" +
            encodeURIComponent("bz/00319030-5990-42ad-af89-d3e57e020134.webp"),
        },
        // ... 其他8个卡片数据，填充有效的图片URL、风格名称和关键字描述
      ],
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      // 模拟网络请求
      setTimeout(() => {
        this.loading = false;
        this.clearForm();
      }, 2000);
    },
    onImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageSrc = URL.createObjectURL(file);
      }
    },
    clearForm() {
      this.selectedStyle = "";
      this.keywords = "";
      this.imageSrc = null;
    },
  },
};
</script>

<style scoped>
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f8f8f8; /* 中性背景 */
  border: 1px dashed gray;
}

.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style-select-wrapper,
.input-group,
.image-upload-wrapper {
  width: 100%;
  margin-bottom: 15px;
}

.style-select-wrapper select,
.input-group input,
.image-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.image-preview {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.submit-button {
  background-color: #5cb85c; /* 生动的颜色 */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #4cae4c;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 列表样式 */
.gallery-container {
  border: 1px dashed gray;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
  background: white; /* 内容背景 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  border-radius: 8px; /* 圆角边框 */
}

.gallery-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-family: "Helvetica", sans-serif; /* 不那么呆板的字体 */
}

.style-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin: 0px auto;
}

.style-card {
  width: calc(33% - 20px);
  /* 卡片整体固定宽高 */
  /* width: 300px; */
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  background: #333; /* 暗黑风格背景 */
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.style-image {
  position: relative;
  width: 100%;
  height: 70%;
}

.style-img {
  width: 100%;
  height: auto;
  opacity: 0.7; /* 图片模糊效果 */
}

.style-name {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.keywords-info {
  padding: 10px;
  background-color: #222;
  color: #fff;
  height: 30%;
  /* overflow-y: auto; 如果内容过多，允许滚动 */
  -webkit-line-clamp: 3; /* 定义文本的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 1.5; 行高 */
  max-height: 4.2em;
}
</style>
